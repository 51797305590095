<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['iossNumbers_iossNumber',])"></div>
      <CardLeftBlock
              :name="''"
              :value="''"
              :backgroundImage="'customs-info'"
      >
        <template slot="button">
          <h2 class="mt-0">{{$t('iossNumbers_iossNumber.localization_value.value')}}</h2>
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['iossNumbers_BackToList',])"></div>
                <router-link :to="$store.getters.GET_PATHS.mainSettingsIOSSNumbers" class="order-create__head-back">
                  <LinkBack
                          :value="$t('iossNumbers_BackToList.localization_value.value')"
                          @click.native="goRouterBack()"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['iossNumbers_createIOSSNumber',])"></div>
              {{$t('iossNumbers_createIOSSNumber.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <FieldsBlock
                :IOSSNumber="IOSSNumber"
                :countries="countries"
            />
          </div>
        </template>

        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel',])"></div>
              <router-link :to="$store.getters.GET_PATHS.mainSettingsIOSSNumbers"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_create'])"></div>

              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_create.localization_value.value')"
                      @click.native="$emit('save', false, 'open')"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import FieldsBlock from "../../chunks/FieldsBlock";

  export default {
    name: "IOSSNumbersCreationSection",

    components: {
      FieldsBlock,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
    },

    props: {
      IOSSNumber: Object,
      countries: Array,
    },

    data() {
      return {

      }
    },

    methods: {
    }
  }
</script>

<style lang="scss">

</style>
