var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--50 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'iossNumbers_category',
        ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.IOSSNumber.validation.category},attrs:{"label":_vm.$t('iossNumbers_category.localization_value.value'),"maxLength":3,"errorTxt":_vm.$t(`${_vm.IOSSNumber.validationTranslate.category}.localization_value.value`),"error":_vm.IOSSNumber.validation.category},model:{value:(_vm.IOSSNumber.data.category),callback:function ($$v) {_vm.$set(_vm.IOSSNumber.data, "category", $$v)},expression:"IOSSNumber.data.category"}})],1)]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--50 custom-col--md-100"},[_c('div',{staticClass:"section-label large font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('common_SelectCountry.localization_value.value'))+" ")]),_c('div',{staticClass:"customs-info__search-input"},[_c('DefaultSelect',{class:{'ui-no-valid': _vm.IOSSNumber.validation.country},attrs:{"options":_vm.countries,"label":_vm.$t('iossNumbers_country.localization_value.value'),"errorTxt":_vm.$t(`${_vm.IOSSNumber.validationTranslate.country}.localization_value.value`),"error":_vm.IOSSNumber.validation.country,"optionsLabel":'label',"otherValue":'currentTranslation',"selected":_vm.IOSSNumber.data.country},on:{"change":_vm.changeCountry}})],1)])]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--50 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'iossNumbers_marketplace',
        ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.IOSSNumber.validation.marketplace},attrs:{"label":_vm.$t('iossNumbers_marketplace.localization_value.value'),"errorTxt":_vm.$t(`${_vm.IOSSNumber.validationTranslate.marketplace}.localization_value.value`),"error":_vm.IOSSNumber.validation.marketplace},model:{value:(_vm.IOSSNumber.data.marketplace),callback:function ($$v) {_vm.$set(_vm.IOSSNumber.data, "marketplace", $$v)},expression:"IOSSNumber.data.marketplace"}})],1)]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--50 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'iossNumbers_number',
        ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.IOSSNumber.validation.number},attrs:{"label":_vm.$t('iossNumbers_number.localization_value.value'),"errorTxt":_vm.$t(`${_vm.IOSSNumber.validationTranslate.number}.localization_value.value`),"error":_vm.IOSSNumber.validation.number},model:{value:(_vm.IOSSNumber.data.number),callback:function ($$v) {_vm.$set(_vm.IOSSNumber.data, "number", $$v)},expression:"IOSSNumber.data.number"}})],1)]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col"},[[_c('div',{staticClass:"section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['iossNumbers_comment'])}}}),_vm._v(" "+_vm._s(_vm.$t('iossNumbers_comment.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col custom-col--66 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['common_widthCm'])}}}),_c('TextareaDefault',{class:{'ui-no-valid': _vm.IOSSNumber.validation.comment},attrs:{"label":_vm.$t('common_comment.localization_value.value'),"error":_vm.IOSSNumber.validation.comment,"errorTxt":_vm.$t(`${_vm.IOSSNumber.validationTranslate.comment}.localization_value.value`)},model:{value:(_vm.IOSSNumber.data.comment),callback:function ($$v) {_vm.$set(_vm.IOSSNumber.data, "comment", $$v)},expression:"IOSSNumber.data.comment"}})],1)])]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }