<template>
  <div class="fragment">

    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'iossNumbers_category',
          ])"></div>
        <DefaultInput
            :label="$t('iossNumbers_category.localization_value.value')"
            :maxLength="3"
            v-bind:class="{'ui-no-valid': IOSSNumber.validation.category}"
            :errorTxt="$t(`${IOSSNumber.validationTranslate.category}.localization_value.value`)"
            :error="IOSSNumber.validation.category"
            v-model="IOSSNumber.data.category"
        />
      </div>
    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100">
        <div class="section-label large font-weight-bold">
          {{$t('common_SelectCountry.localization_value.value')}}
        </div>
        <div class="customs-info__search-input">
          <DefaultSelect
              :options="countries"
              :label="$t('iossNumbers_country.localization_value.value')"
              v-bind:class="{'ui-no-valid': IOSSNumber.validation.country}"
              :errorTxt="$t(`${IOSSNumber.validationTranslate.country}.localization_value.value`)"
              :error="IOSSNumber.validation.country"
              :optionsLabel="'label'"
              :otherValue="'currentTranslation'"
              @change="changeCountry"
              :selected="IOSSNumber.data.country"
          />
        </div>
      </div>
    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'iossNumbers_marketplace',
          ])"></div>
        <DefaultInput
            :label="$t('iossNumbers_marketplace.localization_value.value')"
            v-bind:class="{'ui-no-valid': IOSSNumber.validation.marketplace}"
            :errorTxt="$t(`${IOSSNumber.validationTranslate.marketplace}.localization_value.value`)"
            :error="IOSSNumber.validation.marketplace"
            v-model="IOSSNumber.data.marketplace"
        />
      </div>
    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'iossNumbers_number',
          ])"></div>
        <DefaultInput
            :label="$t('iossNumbers_number.localization_value.value')"
            v-bind:class="{'ui-no-valid': IOSSNumber.validation.number}"
            :errorTxt="$t(`${IOSSNumber.validationTranslate.number}.localization_value.value`)"
            :error="IOSSNumber.validation.number"
            v-model="IOSSNumber.data.number"
        />
      </div>
    </div>

    <div class="custom-row">
      <div class="custom-col">

        <template>
          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['iossNumbers_comment'])"></div>
            {{ $t('iossNumbers_comment.localization_value.value') }}
          </div>
          <div class="order-create__row custom-row">
            <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_widthCm'])"></div>
              <TextareaDefault
                  :label="$t('common_comment.localization_value.value')"
                  v-bind:class="{'ui-no-valid': IOSSNumber.validation.comment}"
                  :error="IOSSNumber.validation.comment"
                  :errorTxt="$t(`${IOSSNumber.validationTranslate.comment}.localization_value.value`)"
                  v-model="IOSSNumber.data.comment"
              />
            </div>
          </div>
        </template>

      </div>
    </div>

  </div>
</template>

<script>
import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import {filterFunc} from "../../../../../mixins/filterFuncMixin/filterFunc";
import {IOSSNumbersMixin} from "../../../../../mixins/IOSSNumbersMixins/IOSSNumbersMixin";
import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";

export default {
  name: "FieldsBlock",

  components: {
    DefaultSelect,
    TextareaDefault,
    DefaultInput,
  },

  mixins: [filterFunc, IOSSNumbersMixin],

  props: {
    IOSSNumber: Object,
    countries: Array,
  },

  mounted() {
  },

  data() {
    return {
      country: '',
    }
  },

  methods: {
    changeCountry(val) {
      this.IOSSNumber.setCountry(val)
    },
  },
}
</script>