<template>
  <IOSSNumbersCreationSection
          :IOSSNumber="IOSSNumber"
          :countries="countries"
          @save="save"
  />
</template>

<script>
  import IOSSNumbersCreationSection from "./IOSSNumbersCreationSection/IOSSNumbersCreationSection";
  import {IOSSNumbers} from "../models/IOSSNumbers";
  import {IOSSNumbersMixin} from "../../../../../mixins/IOSSNumbersMixins/IOSSNumbersMixin";

  export default {
    name: "IOSSNumbersCreation",

    components:{
      IOSSNumbersCreationSection,
    },

    mixins: [IOSSNumbersMixin],

    data() {
      return {
        IOSSNumber: new IOSSNumbers(),
        countries: [],
      }
    },

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        this.countries = this._.toArray(this.$store.getters.getCountries)
      })
    },

    methods: {
    }

  }
</script>

<style scoped>

</style>
