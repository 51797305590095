import {checkValid} from "../../../../globalModels/functions/ModelsValidate";

export function IOSSNumbers() {

    this.checkValid = checkValid

    this.data = {
        id: '',
        category: '',
        country: '',
        marketplace: '',
        comment: '',
        number: '',
    }

    this.validation = {
        id: false,
        category: false,
        country: false,
        marketplace: false,
        comment: false,
        number: false,
    }

    this.validationTranslate = {
        id: '',
        category: '',
        country: '',
        marketplace: '',
        comment: '',
        number: '',
    }

    this.validationTxt = {
        id: false,
        category: false,
        country: false,
        marketplace: false,
        comment: false,
        number: false,
    }

    /**
     * Getters
     */
    this.getId = () => {
        return this.data.id
    }
    this.getCategory = () => {
        return this.data.category
    }
    this.getCountry = () => {
        return this.data.country
    }
    this.getMarketplace = () => {
        return this.data.marketplace
    }
    this.getComment = () => {
        return this.data.comment
    }
    this.getNumber = () => {
        return this.data.number
    }

    /**
     * Setters
     */
    this.setId = (val) => {
        this.data.id = val
    }
    this.setCategory = (val) => {
        this.data.category = val
    }
    this.setCountry = (val) => {
        this.data.country = val
    }
    this.setMarketplace = (val) => {
        this.data.marketplace = val
    }
    this.setComment = (val) => {
        this.data.comment = val
    }
    this.setNumber = (val) => {
        this.data.number = val
    }
}

/**
 * Global Setters
 */

IOSSNumbers.prototype.setItem = function (item) {

    this.setId(item?.id)
    this.setCategory(item?.category)
    this.setCountry(item?.country)
    this.setMarketplace(item?.marketplace)
    this.setComment(item?.comment)
    this.setNumber(item?.number)
}

/**
 * Prepare Data
 */

IOSSNumbers.prototype.prepareSave = function () {
    return {
        'category': this.getCategory(),
        'country_id': this.getCountry()?.id,
        'marketplace': this.getMarketplace(),
        'comment': this.getComment(),
        'number': this.getNumber(),
    }
}

/**
 * Validations
 */

IOSSNumbers.prototype.firstValidationUser = function () {

    let validationItems = {
        category: this.getCategory(),
        country: this.getCountry(),
        marketplace: this.getMarketplace(),
        number: this.getNumber(),

    }

    let validationOptions = {
        category: {type: ['empty']},
        country: {type: ['empty']},
        marketplace: {type: ['empty']},
        number: {type: ['empty']},
    }

    return (this.checkValid(validationItems, validationOptions))
}
